<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <div>
      <Toolbar class="p-mb-4" style="background-color: #fff;border:none;padding-bottom:0px;">
        <template #left>
          <Button v-if="buttons.tjjsBt" @click="addOrUpdate()" style="background: #12A25D;border-color: #12A25D" label="添加角色" icon="pi pi-plus" class="p-mr-2"/>
        </template>

        <template #right>
          <div class="p-inputgroup">
            <InputText style="width:200px;" v-model="name" placeholder="请输入角色名称"/>
            <Button icon="pi pi-search" @click="getList" @keydown.enter="getList" style="background: #FFFFFF;color: #000000;opacity: 0.25;"/>
          </div>
        </template>
      </Toolbar>
      <DataTable  :value="list" :scrollable="true"  :row-hover="true">
        <template #empty>
          没有发现数据。
        </template>
        <Column field="name" header="角色名称"></Column>
        <Column field="code" header="编号"></Column>
        <Column field="enable" header="启用">
          <template #body="slotProps">
          <span :class="slotProps.data.enable == 0 ? 'admin-status' : 'danger-status'">
            <label style="width: 100%;display: block;text-align: center;line-height: 22px">{{
                slotProps.data.enable == 0 ? '启用' : '禁用'
              }}</label>
          </span>
        </template>
        </Column>
        <Column frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:15rem;">
          <template #body="data">
            <a v-if="buttons.bjBt" style="padding-right:8px; margin:0;border-right:1px solid rgba(216, 216, 216, 1)" href="javascript:"
               class="a-button" @click="addOrUpdate(data.data)">编辑</a>
            <a v-if="buttons.fpcdBt" style="padding:0 8px;margin:0;" href="javascript:"
               class="a-button" @click="addOrUp(data.data)">分配菜单</a>
            <a v-if="buttons.scBt" style="padding:0 8px;border-left:1px solid rgba(216, 216, 216, 1);"
               href="javascript:" class="a-button" @click="deleteItem(data.data)">删除</a>
          </template>
        </Column>
        <template #paginatorLeft></template>
      </DataTable>
      <Paginator :rows="page.size" :totalRecords="page.total"
                 @page="changePage"
                 template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
                 :rowsPerPageOptions="[10,20,50,100]"
                 currentPageReportTemplate="共{totalRecords} 条记录"
                >
        <template #right>
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber v-model="page.current" @keydown.enter="getList"  v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;"/>
          <label>&nbsp;&nbsp;页</label>
        </template>
      </Paginator>
      <add-or-update-role ref="addOrUpdateRole" v-if="addOrUpdateDisplay"
                                  v-on:close="closeDialog"></add-or-update-role>
      <add-or-up-role ref="addOrUpRole" v-if="addOrUpdateDisplay"
                          v-on:close="closeDialog"></add-or-up-role>
    </div>
  </div>
</template>

<script>
import AddOrUpdateRole from "./addOrUpdateRole";
import AddOrUpRole from "./addOrUpRole";


export default {
  name: "Role",
  components: {AddOrUpdateRole,AddOrUpRole},
  data() {
    return {
      list: [],
      page: {
        size: 10,
        total: '',
        current: 1,
      },
      addOrUpdateDisplay: false,
      breadcrumb: {
        home: { label: "系统管理", to: "#" },
        items: [{ label: "角色管理", to: "#" }],
      },
      name: null,
      buttons:{
        tjjsBt:false,
        bjBt:false,
        fpcdBt:false,
        scBt:false,
      }
    }
  },
  created() {
    this.getList();
    // this.addNewGoods()
  },
  mounted(){
    if (localStorage.getItem("menuList")) {
      JSON.parse(localStorage.getItem("menuList")).forEach((item, index) => {
        if (
          item.name == "系统管理" &&
          JSON.parse(localStorage.getItem("menuList"))[index].children
        ) {
          JSON.parse(localStorage.getItem("menuList"))[index].children.forEach(
            (v, i) => {
              if (
                v.name == "角色管理" &&
                JSON.parse(localStorage.getItem("menuList"))[index].children[i]
                  .buttons
              ) {
                JSON.parse(localStorage.getItem("menuList"))[index].children[
                  i
                ].buttons.forEach((a) => {
                  switch (a.name) {
                    case "添加角色":
                      this.buttons.tjjsBt = true;
                      break;
                    case "编辑":
                      this.buttons.bjBt = true;
                      break;
                    case "分配菜单":
                      this.buttons.fpcdBt = true;
                      break;
                    case "删除":
                      this.buttons.scBt = true;
                      break;
                    default:
                      return;
                  }
                });
              }
            }
          );
        }
      });
    }
  },
  methods: {
    getList(){
      let me = this;
      this.$http('/role/getList', 'get', {
        "current": this.page.current, "size": this.page.size,
        name:this.name,
        enable:null,
      }, function (res) {
        // console.log(res.data)
        me.page.total=res.data.total
        me.list = res.data.records
        res.data.records.forEach(item=>{
          if (item.name == '管理员' && '用户' && '农场主'){
            console.log(item)
          }
        })
      })
    },
    addOrUpdate(data) {
      this.addOrUpdateDisplay = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRole.init(data)
      })
    },
    addOrUp(data) {
      this.addOrUpdateDisplay = true
      this.$nextTick(() => {
        this.$refs.addOrUpRole.init(data)
      })
    },
    closeDialog() {
      this.addOrUpdateDisplay = false
      this.getList()
    },
    changePage(page) {
      console.log(page)
      this.page.size = page.rows
      this.page.current = page.page + 1
      this.getList()
    },

    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除"' + data.name + '"吗?',
        header: '删除本条数据',
        icon: 'pi pi-info-circle',
        acceptLabel: '确定',
        accept: () => {
          // console.log(data)
          this.delete(data)
        },
        rejectLabel: '取消',
        reject: () => {
        }
      })
    },
    delete(data){
      console.log(data.id)
      let m = this
      this.$http('/role/del/'+ data.id, 'delete', null, function (res) {
        console.log(res)
        m.getList()
      })
    }
  }
}
</script>

<style scoped>
p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  cursor: pointer;
}

h3 {
  padding: 1rem 0 1rem 10px;
  line-height: 1;
  font-weight: 800;
  position: relative;
}

h3::after {
  content: '';
  width: 5px;
  height: 16px;
  background-color: var(--primary-color);
  position: absolute;
  left: 0px;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  min-width: 100px;
  white-space: nowrap;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td){
  height: 4rem;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91D5FF;
  color: #1890FF;
  font-size: 12px;
  background-color: #E6F7FF;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #B7EB8F;
  color: #52C41A;
  font-size: 12px;
  background-color: #F6FFED;
}

.danger-status{
  width: 56px;
  height: 22px;
  border: 1px double #f47983;
  color: #f00056;
  font-size: 12px;
  background-color: #ffb3a7;
}

.a-button {
  color: #12A25D;
  background-color: unset;
  border: none;
}
</style>
